<template>
    <div v-if="show" class="relative z-[52]">
        <div
            class="pointer-events-none fixed inset-0 overscroll-none bg-black bg-opacity-70 transition-opacity"
        ></div>

        <div class="fixed inset-0 z-[1001] overflow-y-auto">
            <div
                class="flex min-h-full items-center justify-center p-4 text-center sm:p-0"
            >
                <div
                    ref="AlertModalWindow"
                    class="relative max-w-[390px] transform overflow-hidden transition-all sm:my-8 lg:w-[390px]"
                    :class="classCustom"
                >
                    <slot name="header"></slot>
                    <slot></slot>
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onClickOutside } from '@vueuse/core'
const { show, sticky } = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    sticky: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'lg',
    },
    classCustom: {
        type: String,
        default: '',
    },
})
const emit = defineEmits(['close'])
const AlertModalWindow = ref(null)

onClickOutside(AlertModalWindow, () => {
    if (!sticky) {
        emit('close')
    }
})
</script>
